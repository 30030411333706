import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import BlockSolution from "../../components/blockSolution/blockSolution"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import StandardTitle from "../../components/standardTitle/StandardTitle"
import BlockApp from "../../components/blockApp/blockApp"
import { graphql, useStaticQuery } from "gatsby"
import SliderCustom from "../../components/sliderCustom/sliderCustom"
import "./index.scss"
import utils from "../../utils/utils"
import { getTaxoMaterials } from "../../hooks/hooks"
import ImgCustom from "../../components/imgCustom/imgCustom"
import tracking from "../../utils/tracking"
import links from "../../utils/links"

const positionPoint = {
  centrale_alarme: {
    positionDesktop: [{ top: "317px", left: "483px" }],
    positionTablet: [{ top: "249px", left: "374px" }],
    positionMobile: { top: "56px", left: "126px" },
    coverMobile: (
      <StaticImage
        loading="lazy"
        objectFit="contain"
        src="../../assets/images/illu_materiel_1.jpg"
        alt="cover illustration"
      ></StaticImage>
    ),
  },
  detect_ouverture: {
    positionDesktop: [{ top: "113px", left: "594px" }],
    positionTablet: [{ top: "91px", left: "457px" }],
    positionMobile: { top: "55px", left: "125px" },
    coverMobile: (
      <StaticImage
        loading="lazy"
        objectFit="contain"
        src="../../assets/images/illu_materiel_2.jpg"
        alt="cover illustration"
      ></StaticImage>
    ),
  },
  detect_fume: {
    positionDesktop: [{ top: "191px", left: "357px" }],
    positionTablet: [{ top: "150px", left: "274px" }],
    positionMobile: { top: "54px", left: "125px" },
    coverMobile: (
      <StaticImage
        loading="lazy"
        objectFit="contain"
        src="../../assets/images/illu_materiel_3.jpg"
        alt="cover illustration"
      ></StaticImage>
    ),
  },
  detect_mouvement: {
    positionDesktop: [
      { top: "72px", left: "215px" },
      { top: "116px", left: "683px" },
    ],
    positionTablet: [
      { top: "60px", left: "169px" },
      { top: "93px", left: "525px" },
    ],
    positionMobile: { top: "53px", left: "125px" },
    coverMobile: (
      <StaticImage
        loading="lazy"
        objectFit="contain"
        src="../../assets/images/illu_materiel_4.jpg"
        alt="cover illustration"
      ></StaticImage>
    ),
  },
  lecteur_badge: {
    positionDesktop: [{ top: "257px", left: "312px" }],
    positionTablet: [{ top: "198px", left: "243px" }],
    positionMobile: { top: "43px", left: "129px" },
    coverMobile: (
      <StaticImage
        loading="lazy"
        objectFit="contain"
        src="../../assets/images/illu_materiel_5.jpg"
        alt="cover illustration"
      ></StaticImage>
    ),
  },
  app_mobile: {
    positionDesktop: [{ top: "192px", left: "516px" }],
    positionTablet: [{ top: "152px", left: "399px" }],
    positionMobile: { top: "73px", left: "136px" },
    coverMobile: (
      <StaticImage
        loading="lazy"
        objectFit="contain"
        src="../../assets/images/illu_materiel_5.jpg"
        alt="cover illustration"
      ></StaticImage>
    ),
  },
  alarm_inside: {
    positionDesktop: [{ top: "164px", left: "358px" }],
    positionTablet: [{ top: "", left: "" }],
    positionMobile: { top: "45px", left: "125px" },
    coverMobile: (
      <StaticImage
        loading="lazy"
        objectFit="contain"
        src="../../assets/images/illu_materiel_6.jpg"
        alt="cover illustration"
      ></StaticImage>
    ),
  },
}

const index = () => {
  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])
  const data = useStaticQuery(graphql`
    query {
      nodePageMateriels {
        field_seo_titre
        field_sous_titre_materiel
        field_titre_
        field_seo_description
        relationships {
          field_block_application {
            field_cover {
              alt
            }
            field_cover_en_mobile {
              alt
            }
            field_titre
            field_sous_titre_ligne
            field_min_description
            relationships {
              field_cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_cover_en_mobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_image_application {
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_image_application_mobile {
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
            }
            field_image_application {
              alt
            }
            field_image_application_mobile {
              alt
            }
          }
          field_hero_header {
            field_cover {
              alt
            }
            field_sous_titre {
              value
            }
            field_titre_hero {
              value
            }
            relationships {
              field_cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_tablette {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_mobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const materielData = getTaxoMaterials().sort((a, b) =>
    a.chrono > b.chrono ? 1 : -1
  )
  const [selectMateriel, setSelectMateriel] = useState(materielData[0])
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const blockSolution = utils.mapHeroHeader(data, "nodePageMateriels")
  const blockApp = utils.mapApp(
    data.nodePageMateriels.relationships.field_block_application
  )
  const seoPage = {
    title: data.nodePageMateriels.field_seo_titre,
    description: data.nodePageMateriels.field_seo_description,
  }

  const handleClickMateriel = elm => {
    setSelectMateriel(elm)
  }
  /*** Add Path image */
  useEffect(() => {
    /** Tracking */
    tracking.track("typeTemplate", "materialspage")
    tracking.track("page_name", seoPage.title + (isTherecampagne ? "/" + campagne : ""))
    tracking.track("categorie", "telesurveillance")
    //tracking.trackinit()
    setIsMobile(document.body.clientWidth < 968)
    setIsTablet(utils.isTabletteSize())
    utils.startPageWindow()
    window.addEventListener("resize", () => {
      setIsMobile(document.body.clientWidth < 968)
      setIsTablet(utils.isTabletteSize())
    })
  }, [])

  return (
    <Layout isViewPromoOnHeader={true}>
      <Seo
        title={seoPage.title}
        description={seoPage.description}
        linkCanonical={"materiels/"}
      />
      {materielData && selectMateriel && (
        <>
          <div className="bc-md-w">
            <div className="wrapper hero-materiel">
              {/** BLOCK SOLUTIONS TOP */}
              <BlockSolution data={blockSolution} />
            </div>
          </div>
          <article className="wrapper no-wrapper no-padding">
            <section className="block-materiels-target py-8">
              <div className="wrapper-materiel-mobile">
                <StandardTitle
                  addClassTitle={"fs-md-24 lh-md-32"}
                  addClassSubTitle={"fs-md-14"}
                  title="Comment protéger efficacement votre logement ?"
                  subTitle="Découvrez notre matériel, son fonctionnement et son intégration dans votre logement"
                />
              </div>
              {isMobile && (
                <ul className="ul w-100 list-mobile-materiels">
                  <SliderCustom
                    settings={{
                      dots: true,
                      infinite: false,
                      speed: 500,
                      arrows: true,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    }}
                  >
                    {materielData.map((el, index) => (
                      <li key={index} className="li bcg-1 br-8  mx-a item">
                        {/** MATERIEL COVER + LABEL */}
                        <div className="cover-item p-r">
                          <div className="cover p-r">
                            <div className="overlay-point-target">
                              {positionPoint[el.name] && (
                                <button
                                  className={"point is-active"}
                                  style={{
                                    top: positionPoint[el.name]?.positionMobile
                                      .top,
                                    left: positionPoint[el.name]?.positionMobile
                                      .left,
                                  }}
                                ></button>
                              )}
                            </div>
                            <ImgCustom
                              src={el.imageMobile.path}
                              alt={el.imageMobile.alt}
                            />
                            {/*positionPoint[el.name]?.coverMobile*/}
                          </div>
                          <div className="label-materiel-mobile box-shadow-s d-f jc-c ai-c fd-c br-8 bc-w">
                            <div className="illu-materiel-container d-f jc-c ai-c ">
                              <div className="illu-materiel-img">
                                <ImgCustom
                                  src={el.icon.path}
                                  alt={el.icon.alt}
                                />
                                {/**
                                <img src={el.icon.path} alt={el.icon.alt} />
                                * 
                                */}
                              </div>
                            </div>
                            <p className="mb-0 fs-16 lh-24 ta-c">{el.label}</p>
                          </div>
                        </div>
                        {/** MATERIEL DETAIL */}
                        <div className="pb-3 px-3 pt-0">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: el.description,
                            }}
                            className="description-text fs-14 lh-24 ff-gbook mb-0 "
                          ></div>
                          <div className="py-3">
                            <span className="btn-tag">
                              {el.isTagOptional
                                ? "Optionnel"
                                : "Inclus dans le pack"}
                            </span>
                          </div>
                          <ul className="ul">
                            {el.detail.map((elm, i) => (
                              <li key={i} className="li py-2 ">
                                <div className="d-f ai-c">
                                  <span
                                    style={{ height: "20px", minWidth: "20px" }}
                                    className={"mr-1  "}
                                  >
                                    <img
                                      src={elm.icon.path}
                                      alt={elm.icon.alt}
                                      className="h-100 w-100 of-contain"
                                    />
                                  </span>
                                  <div
                                    className="integrate-html"
                                    dangerouslySetInnerHTML={{
                                      __html: elm.html,
                                    }}
                                  ></div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {el.dimension && (
                            <p className="ff-gbook  mt-2 fs-14 lh-24 mb-0 d-f fd-c">
                              Dimensions :{" "}
                              <span className="ff-gm">{el.dimension}</span>
                            </p>
                          )}
                        </div>
                      </li>
                    ))}
                  </SliderCustom>
                </ul>
              )}
              {!isMobile && (
                <>
                  {/** Liste Materiels */}
                  <div className="py-4 mb-4 w-100 d-f jc-c">
                    <ul className="ul list-materiels d-f ai-fs">
                      {materielData.map((elm, index) => (
                        <li className="li px-3" key={index}>
                          <button
                            onClick={() => handleClickMateriel(elm)}
                            className={
                              selectMateriel.name === elm.name
                                ? "item d-c fd-c jc-c ai-c is-active"
                                : "item d-c fd-c jc-c ai-c"
                            }
                          >
                            <div className="img d-f jc-c ai-c mx-a">
                              <ImgCustom
                                src={elm.icon.path}
                                alt={elm.icon.alt}
                              />
                              {/**
                               * 
                              <img src={elm.icon.path} alt={elm.icon.alt}></img>
                               */}
                            </div>
                            <h3 className="target-label mb-0 mt-1 fs-14 lh-24 ta-c">
                              {elm.label}
                            </h3>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/** Materiel detail */}
                  <div className="d-f ai-fs">
                    <div className="cover-target p-r">
                      <div className="overlay-point-target">
                        {materielData.map((elm, index) => (
                          <div key={index}>
                            {positionPoint[elm.name] && (
                              <>
                                {!isTablet ? (
                                  <>
                                    {positionPoint[
                                      elm.name
                                    ].positionDesktop.map(
                                      (point, point_index) => (
                                        <button
                                          key={point_index}
                                          onClick={() => setSelectMateriel(elm)}
                                          className={
                                            elm.name === selectMateriel.name
                                              ? "point is-active"
                                              : "point"
                                          }
                                          style={{
                                            top: point.top,
                                            left: point.left,
                                          }}
                                        ></button>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {positionPoint[elm.name].positionTablet.map(
                                      (point, point_index) => (
                                        <button
                                          key={point_index}
                                          onClick={() => setSelectMateriel(elm)}
                                          className={
                                            elm.name === selectMateriel.name
                                              ? "point is-active"
                                              : "point"
                                          }
                                          style={{
                                            top: point.top,
                                            left: point.left,
                                          }}
                                        ></button>
                                      )
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                      <StaticImage
                        src={"../../assets/images/cover_materiels_target.jpg"}
                        alt="cover illustration"
                      />
                    </div>
                    <div className="materiel-details pl-3">
                      {materielData.map((element, index) => (
                        <div
                          key={index}
                          className={
                            element.name !== selectMateriel.name
                              ? "content d-n p-4 bcg-2"
                              : "content p-4 bcg-2"
                          }
                        >
                          <h3 className="fs-24 lh-32 ta-c ff-gb">
                            {element.label}
                          </h3>
                          <div className="d-f ai-c">
                            <div
                              style={{ height: "80px", minWidth: "80px" }}
                              className="current-materiel-image d-f ai-c jc-c"
                            >
                              <ImgCustom
                                src={element.icon.path}
                                alt={element.icon.alt}
                              />
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: element.description,
                              }}
                              className="description-text fs-14 lh-24 ff-gbook mb-0 px-2"
                            ></div>
                          </div>
                          <div className="py-3">
                            <span className="btn-tag">
                              {element.isTagOptional
                                ? "Optionnel"
                                : "Inclus dans le pack"}
                            </span>
                          </div>
                          <ul className="ul">
                            {element.detail.map((el, index) => (
                              <li key={index} className="li py-2">
                                <div className="d-f ai-c">
                                  <span
                                    style={{ height: "20px", minWidth: "20px" }}
                                    className={"mr-1 "}
                                  >
                                    <img
                                      className="h-100 w-100 of-contain"
                                      alt={el.icon.alt}
                                      src={el.icon.path}
                                    />
                                  </span>
                                  <div
                                    className="integrate-html"
                                    dangerouslySetInnerHTML={{
                                      __html: el.html,
                                    }}
                                  ></div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {element.dimension && (
                            <p className="ff-gbook  mt-2 fs-14 lh-24 mb-0">
                              Dimensions :{" "}
                              <span className="ff-gm">{element.dimension}</span>
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </section>
          </article>
        </>
      )}
      {/** BLOCK APP */}
      <BlockApp data={blockApp} />
      {/** END BLOCK APP */}
    </Layout>
  )
}

export default index
